export default {
  data() {
    return {
      increment: 0,
      iord: 0.0,
      membersOwing: [],
      membersDuesCollected: 0,
      membersOutstandingDues: 0,
      pensionContributions: 0,
      organizationDues: 0,
      organizationOutstandingDues: 0,
      totalGrants: 0,
      memberDuesPaymentsKey: false,
      memberDuesPaymentsChart: {
        series: [{
          name: 'This Year',
          data: [],
        },
        {
          name: 'Last Year',
          data: [],
        },
        ],

        chartOptions: {
          grid: {
            borderColor: '#ebebeb',
            padding: {
              left: 0,
              right: 0,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            stacked: true,
            type: 'bar',
            toolbar: { show: false },
          },
          colors: ['#afed42', '#ffc62b'],
          plotOptions: {
            bar: {
              columnWidth: '40%',
            },
          },
          xaxis: {
            labels: {
              style: {
                cssClass: 'text-grey fill-current',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            axisBorder: {
              show: false,
            },
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                cssClass: 'text-grey fill-current',
              },
            },
          },
          tooltip: {
            x: { show: false },
            y: {
              formatter: value => {
                if (value === 0) return value
                if (!value) return ''
                const formatted = new Intl.NumberFormat('en-US', {
                  minimumFractionDigits: 2,
                }).format(value)
                return `${this.$store.getters['company/currencySymbol']}${formatted} recorded`
              },
            },
          },
        },

      },
    }
  },
}
