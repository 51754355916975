<template>
  <div class="contribution-dashboard">
    <b-row>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          :statistic="`${currencySymbol} ${summary.member_dues_collected}`"
          statistic-title="Member Dues Collected"
          @click="goTo('payments')"
        />
      </b-col>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          color="danger"
          :statistic="`${currencySymbol} ${summary.member_dues_outstanding}`"
          statistic-title="Member Dues Outstanding"
          @click="goTo('outstanding')"
        />
      </b-col>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          color="success"
          :statistic="`${currencySymbol} ${summary.pension_collected}`"
          statistic-title="Pension Collected"
          @click="goTo('payments')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          :statistic="`${currencySymbol} ${summary.pension_outstanding}`"
          color="danger"
          statistic-title="Pension Outstanding"
          @click="goTo('outstanding')"
        />
      </b-col>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          color="success"
          :statistic="`${currencySymbol} ${summary.organization_dues_collected}`"
          statistic-title="Farmer Group Dues Collected"
          @click="goTo('payments')"
        />
      </b-col>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          color="danger"
          :statistic="`${currencySymbol} ${summary.organization_dues_outstanding}`"
          statistic-title="Farmer Group Dues Outstanding"
          @click="goTo('outstanding')"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          :statistic="`${currencySymbol} ${summary.registration_fee_collected}`"
          color="success"
          statistic-title="Registration Fees Collected"
          @click="goTo('payments')"
        />
      </b-col>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          color="danger"
          :statistic="`${currencySymbol} ${summary.registration_fee_outstanding}`"
          statistic-title="Registration Fees Outstanding"
          @click="goTo('outstanding')"
        />
      </b-col>
      <b-col
        md="4"
      >
        <statistic-card-horizontal
          icon="CpuIcon"
          color="success"
          :statistic="`${currencySymbol} ${summary.grants_received}`"
          statistic-title="Grants Received"
          @click="goTo('grants')"
        />
      </b-col>
    </b-row>
    <div class="row mb-5">
      <div class="col-md-6">
        <am-overlay :show-overlay="duesPaymentLoading">
          <b-card
            no-body
            style="height: 450px !important;"
          >
            <b-card-header>
              <b-card-sub-title>Dues Payments</b-card-sub-title>
              <am-select
                v-model="selectedDuesPayment"
                placeholder="Dues Type"
                :options="duesTypes"
                @input="loadDuesPayments"
              />
            </b-card-header>
            <b-card-body>
              <div class="d-flex">
                <span class="d-flex align-items-center mr-1"><div
                  class="bg-primary rounded"
                /><span>{{ last_year }}</span></span>
                <span class="d-flex align-items-center"><div class="bg-warning rounded" /><span>{{ this_year }}</span></span>
                <div
                  class="d-flex align-items-center ml-4"
                >
                  <feather-icon
                    :style="increment?'color: green;':'color: red;'"
                    :icon="increment?'ArrowUpIcon':'ArrowDownIcon'"
                    size="18"
                  />
                  <span> {{ currencySymbol }}{{ iord | currency }}</span>
                </div>
              </div>
              <vue-apex-charts
                :key="memberDuesPaymentsKey"
                type="bar"
                height="277"
                :options="memberDuesPaymentsChart.chartOptions"
                :series="memberDuesPaymentsChart.series"
              />
            </b-card-body>
          </b-card>
        </am-overlay>
      </div>

      <div class="col-md-6">
        <am-overlay :show-overlay="outstandingLoading">
          <b-card
            no-body
            style="height: 450px !important;"
          >
            <b-card-header>
              <b-card-sub-title>Outstanding Dues</b-card-sub-title>
              <am-select
                v-model="selectedOutstandingDues"
                placeholder="Dues Type"
                :options="duesTypes"
                @input="loadOutstandingDues"
              />
            </b-card-header>
            <b-card-body>
              <b-avatar-group size="50px">
                <b-avatar
                  v-for="(out,index) in outstanding"
                  :key="index"
                  v-b-tooltip.hover
                  class="pull-up"
                  :title="`${out.name} - Owing (${currencySymbol}${out.outstanding})`"
                  :src="`${basePath}/${out.photo}`"
                />
              </b-avatar-group>
              <div class="d-flex justify-content-center mt-5">
                <am-button
                  variant="warning"
                  @click="goTo('outstanding')"
                >
                  Show All
                </am-button>

              </div>
            </b-card-body>

          </b-card>
        </am-overlay>
      </div>

    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
// eslint-disable-next-line import/extensions
import ECharts from 'vue-echarts/components/ECharts'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import {
  BRow, BCol, BCard, BCardHeader, BCardBody, BCardSubTitle, BAvatarGroup, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import AmButton from '@/farmbusiness/components/AmButton.vue'
import AmSelect from '@/farmbusiness/components/AmSelect.vue'
import AmOverlay from '@/farmbusiness/components/AmOverlay.vue'
import contributionData from './contributionData'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import theme from '../theme.json'

ECharts.registerTheme('ovilia-green', theme)
export default {
  components: {
    AmOverlay,
    AmSelect,
    AmButton,
    VueApexCharts,
    StatisticCardHorizontal,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BAvatarGroup,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [contributionData],
  data() {
    return {
      duesTypes: ['Member Dues', 'Farmer Group Dues'],
      selectedDuesPayment: null,
      selectedOutstandingDues: null,
      duesPaymentLoading: false,
      outstandingLoading: false,
      last_year: 'Last Year',
      this_year: 'This Year',
    }
  },
  computed: {
    dashboardSettings() {
      return this.$store.getters['contributionDashboard/dashboardSettings']
    },
    basePath() {
      return process.env.VUE_APP_STORAGE_PATH
    },
    summary() {
      return this.$store.getters['contributionDashboard/summary']
    },
    duesPayments() {
      return this.$store.getters['contributionDashboard/duesPayments']
    },
    outstanding() {
      return this.$store.getters['contributionDashboard/outstanding']
    },
    currencySymbol() {
      return this.$store.getters['company/currencySymbol']
    },
  },
  watch: {
    duesPayments(val) {
      const curr = `This Year (${val.this_year})`
      const prev = `Last Year (${val.last_year})`
      this.memberDuesPaymentsChart.series[0].data = val.this_year_data
      this.memberDuesPaymentsChart.series[0].name = curr
      this.memberDuesPaymentsChart.series[1].data = val.last_year_data
      this.memberDuesPaymentsChart.series[1].name = prev
      this.increment = val.incr
      this.iord = val.change
      this.this_year = curr
      this.last_year = prev
      this.memberDuesPaymentsKey = !this.memberDuesPaymentsKey
    },
  },
  mounted() {
    this.selectedOutstandingDues = this.dashboardSettings.selectedOutstandingDues
    this.selectedDuesPayment = this.dashboardSettings.selectedDuesPayment
    this.$store.dispatch('contributionDashboard/getSummary')
  },
  methods: {
    goTo(name) {
      this.$router.push({ name })
    },
    loadDuesPayments(type) {
      this.$store.commit('contributionDashboard/setSettings', { selectedDuesPayment: this.selectedDuesPayment })
      this.duesPaymentLoading = true
      this.$store.dispatch('contributionDashboard/getDuesPayments', type).finally(() => {
        this.duesPaymentLoading = false
      })
    },
    loadOutstandingDues(type) {
      this.$store.commit('contributionDashboard/setSettings', { selectedOutstandingDues: this.selectedOutstandingDues })
      this.outstandingLoading = true
      this.$store.dispatch('contributionDashboard/getOutstandingDues', type === 'Member Dues').finally(() => {
        this.outstandingLoading = false
      })
    },
  },
}
</script>

<style>

.rounded {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  margin-right: 5px;
}
</style>
